var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.bigLoading,
          expression: "bigLoading",
        },
      ],
      staticClass: "orderTest-container",
    },
    [
      _c(
        "div",
        { staticClass: "topInput" },
        [
          _c(
            "el-select",
            {
              attrs: {
                filterable: "",
                clearable: "",
                placeholder: "选择出货仓库",
                "popper-class": "select-idx",
              },
              model: {
                value: _vm.queryForm.depot_id,
                callback: function ($$v) {
                  _vm.$set(_vm.queryForm, "depot_id", $$v)
                },
                expression: "queryForm.depot_id",
              },
            },
            _vm._l(_vm.depotList, function (item) {
              return _c("el-option", {
                key: item.id,
                attrs: { label: item.name, value: item.id },
              })
            }),
            1
          ),
          _c(
            "el-select",
            {
              attrs: {
                filterable: "",
                clearable: "",
                multiple: "",
                "collapse-tags": true,
                placeholder: "选择业务员",
                "popper-class": "select-idx",
              },
              model: {
                value: _vm.queryForm.staff_id,
                callback: function ($$v) {
                  _vm.$set(_vm.queryForm, "staff_id", $$v)
                },
                expression: "queryForm.staff_id",
              },
            },
            _vm._l(_vm.saleManList, function (item) {
              return _c("el-option", {
                key: item.id,
                attrs: { label: item.name, value: item.id },
              })
            }),
            1
          ),
          _c(
            "el-select",
            {
              attrs: {
                filterable: "",
                clearable: "",
                placeholder: "是否结清",
                "popper-class": "select-idx",
              },
              model: {
                value: _vm.queryForm.pay_status,
                callback: function ($$v) {
                  _vm.$set(_vm.queryForm, "pay_status", $$v)
                },
                expression: "queryForm.pay_status",
              },
            },
            _vm._l(_vm.orderStatusList, function (item) {
              return _c("el-option", {
                key: item.id,
                attrs: { label: item.name, value: item.id },
              })
            }),
            1
          ),
          _c(
            "el-select",
            {
              attrs: {
                filterable: "",
                clearable: "",
                placeholder: "状态",
                "popper-class": "select-idx",
              },
              model: {
                value: _vm.queryForm.bill_status,
                callback: function ($$v) {
                  _vm.$set(_vm.queryForm, "bill_status", $$v)
                },
                expression: "queryForm.bill_status",
              },
            },
            _vm._l(_vm.allotStatusList, function (item) {
              return _c("el-option", {
                key: item.id,
                attrs: { label: item.name, value: item.id },
              })
            }),
            1
          ),
          _c(
            "el-select",
            {
              attrs: {
                filterable: "",
                clearable: "",
                placeholder: "到货时间",
                "popper-class": "select-idx",
              },
              model: {
                value: _vm.queryForm.time_type,
                callback: function ($$v) {
                  _vm.$set(_vm.queryForm, "time_type", $$v)
                },
                expression: "queryForm.time_type",
              },
            },
            _vm._l(_vm.orderTimeList, function (item) {
              return _c("el-option", {
                key: item.id,
                attrs: { label: item.name, value: item.id },
              })
            }),
            1
          ),
          _c(
            "el-tooltip",
            {
              staticClass: "item",
              attrs: {
                effect: "dark",
                content:
                  "默认显示6个月内的订单，可点击时间框修改起止时间，查询其他时段订单",
                placement: "top-start",
              },
            },
            [
              _c("el-date-picker", {
                attrs: {
                  type: "daterange",
                  "range-separator": "-",
                  "value-format": "yyyy-MM-dd",
                  "start-placeholder": "开始日期",
                  "popper-class": "select-idx",
                  "end-placeholder": "结束日期",
                },
                model: {
                  value: _vm.queryForm.time,
                  callback: function ($$v) {
                    _vm.$set(_vm.queryForm, "time", $$v)
                  },
                  expression: "queryForm.time",
                },
              }),
            ],
            1
          ),
          _c(
            "el-select",
            {
              attrs: { clearable: "", placeholder: "请选择线路" },
              model: {
                value: _vm.queryForm.line_id,
                callback: function ($$v) {
                  _vm.$set(_vm.queryForm, "line_id", $$v)
                },
                expression: "queryForm.line_id",
              },
            },
            _vm._l(_vm.lineSelect, function (i, idx) {
              return _c("el-option", {
                key: idx,
                attrs: { value: i.id, label: i.line_name },
              })
            }),
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "more" },
        [
          _c(
            "el-collapse",
            {
              model: {
                value: _vm.activeNames,
                callback: function ($$v) {
                  _vm.activeNames = $$v
                },
                expression: "activeNames",
              },
            },
            [
              _c(
                "el-collapse-item",
                { attrs: { title: "更多筛选条件", name: "1" } },
                [
                  _c("el-cascader", {
                    staticStyle: { width: "140px", margin: "5px" },
                    attrs: {
                      options: _vm.areaList,
                      placeholder: "选择区域",
                      props: {
                        checkStrictly: true,
                        value: "id",
                        label: "name",
                        emitPath: false,
                      },
                      clearable: "",
                    },
                    model: {
                      value: _vm.queryForm.cust_area_id,
                      callback: function ($$v) {
                        _vm.$set(_vm.queryForm, "cust_area_id", $$v)
                      },
                      expression: "queryForm.cust_area_id",
                    },
                  }),
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "140px", margin: "5px" },
                      attrs: {
                        placeholder: "选择渠道类型",
                        "popper-class": "select-idx",
                        filterable: "",
                        clearable: "",
                      },
                      model: {
                        value: _vm.queryForm.cust_channel_id,
                        callback: function ($$v) {
                          _vm.$set(_vm.queryForm, "cust_channel_id", $$v)
                        },
                        expression: "queryForm.cust_channel_id",
                      },
                    },
                    _vm._l(_vm.cust_channel, function (item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.name, value: item.id },
                      })
                    }),
                    1
                  ),
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "140px", margin: "5px" },
                      attrs: {
                        placeholder: "客户等级",
                        "popper-class": "select-idx",
                        filterable: "",
                        clearable: "",
                      },
                      model: {
                        value: _vm.queryForm.cust_class_id,
                        callback: function ($$v) {
                          _vm.$set(_vm.queryForm, "cust_class_id", $$v)
                        },
                        expression: "queryForm.cust_class_id",
                      },
                    },
                    _vm._l(_vm.cust_class, function (item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.name, value: item.id },
                      })
                    }),
                    1
                  ),
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "140px", margin: "5px" },
                      attrs: {
                        placeholder: "司机",
                        "popper-class": "select-idx",
                        filterable: "",
                        clearable: "",
                      },
                      model: {
                        value: _vm.queryForm.deliver_id,
                        callback: function ($$v) {
                          _vm.$set(_vm.queryForm, "deliver_id", $$v)
                        },
                        expression: "queryForm.deliver_id",
                      },
                    },
                    _vm._l(_vm.deliver, function (item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.name, value: item.id },
                      })
                    }),
                    1
                  ),
                  _c("span", { staticStyle: { "margin-left": "10px" } }, [
                    _vm._v("生产日期:"),
                  ]),
                  _c("el-date-picker", {
                    staticStyle: { width: "290px", margin: "5px" },
                    attrs: {
                      type: "daterange",
                      "range-separator": "-",
                      "value-format": "yyyy-MM-dd",
                      "start-placeholder": "开始日期",
                      "end-placeholder": "结束日期",
                    },
                    model: {
                      value: _vm.time2,
                      callback: function ($$v) {
                        _vm.time2 = $$v
                      },
                      expression: "time2",
                    },
                  }),
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "140px", margin: "5px" },
                      attrs: {
                        placeholder: "打印状态",
                        "popper-class": "select-idx",
                        filterable: "",
                        clearable: "",
                      },
                      model: {
                        value: _vm.queryForm.print,
                        callback: function ($$v) {
                          _vm.$set(_vm.queryForm, "print", $$v)
                        },
                        expression: "queryForm.print",
                      },
                    },
                    _vm._l(_vm.print, function (item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.name, value: item.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("div", { staticClass: "table-wrap" }, [
        _c(
          "div",
          { staticClass: "table-container" },
          [
            _c(
              "vab-query-form",
              [
                _c(
                  "vab-query-form-top-panel",
                  [
                    _c(
                      "el-form",
                      {
                        ref: "form",
                        attrs: {
                          inline: true,
                          model: _vm.queryForm,
                          "label-width": "80px",
                        },
                        nativeOn: {
                          submit: function ($event) {
                            $event.preventDefault()
                          },
                        },
                      },
                      [
                        _c(
                          "el-form-item",
                          [
                            _c("client-search", {
                              attrs: { "popper-class": "select-idx selectDC" },
                              on: {
                                "select-id": _vm.selectId,
                                "select-val-input": _vm.custInput,
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { prop: "brand" } },
                          [
                            _c("brand-select", {
                              ref: "brandSelect",
                              staticStyle: { width: "160px" },
                              attrs: {
                                tags: true,
                                duoxuan: true,
                                "popper-class": "select-idx",
                              },
                              on: { "brand-select-change": _vm.chooseBrand },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "", prop: "" } },
                          [
                            _c("el-input", {
                              attrs: { placeholder: "商品名称" },
                              model: {
                                value: _vm.queryForm.keyword_goods,
                                callback: function ($$v) {
                                  _vm.$set(_vm.queryForm, "keyword_goods", $$v)
                                },
                                expression: "queryForm.keyword_goods",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "", prop: "" } },
                          [
                            _c("el-input", {
                              attrs: { placeholder: "单号或单据备注" },
                              model: {
                                value: _vm.queryForm.remark,
                                callback: function ($$v) {
                                  _vm.$set(_vm.queryForm, "remark", $$v)
                                },
                                expression: "queryForm.remark",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "", prop: "" } },
                          [
                            _c(
                              "el-select",
                              {
                                attrs: {
                                  placeholder: "来源",
                                  "popper-class": "select-idx",
                                  filterable: "",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.queryForm.bill_type,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.queryForm, "bill_type", $$v)
                                  },
                                  expression: "queryForm.bill_type",
                                },
                              },
                              _vm._l(_vm.bill_type, function (item) {
                                return _c("el-option", {
                                  key: item.id,
                                  attrs: { label: item.name, value: item.id },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "", prop: "" } },
                          [
                            _c(
                              "el-select",
                              {
                                attrs: {
                                  placeholder: "结算方式",
                                  "popper-class": "select-idx",
                                  filterable: "",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.queryForm.account_type,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.queryForm, "account_type", $$v)
                                  },
                                  expression: "queryForm.account_type",
                                },
                              },
                              _vm._l(_vm.account_type, function (item) {
                                return _c("el-option", {
                                  key: item.id,
                                  attrs: { label: item.name, value: item.id },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "", prop: "" } },
                          [
                            _c(
                              "el-select",
                              {
                                attrs: {
                                  placeholder: "销售类型",
                                  "popper-class": "select-idx",
                                  filterable: "",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.queryForm.sale_type,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.queryForm, "sale_type", $$v)
                                  },
                                  expression: "queryForm.sale_type",
                                },
                              },
                              _vm._l(_vm.sale_type, function (item) {
                                return _c("el-option", {
                                  key: item.id,
                                  attrs: { label: item.name, value: item.id },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          [
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  icon: "el-icon-search",
                                  type: "primary",
                                },
                                on: { click: _vm.btnQuery },
                              },
                              [_vm._v(" 查询 ")]
                            ),
                            _c("el-button", { on: { click: _vm.btnExpotr } }, [
                              _vm._v("导出"),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "el-table",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.listLoading,
                    expression: "listLoading",
                  },
                ],
                ref: "tableSort",
                attrs: {
                  stripe: "",
                  data: _vm.list,
                  "row-key": "id",
                  "max-height": "800",
                  border: "",
                  "show-summary": "",
                  "summary-method": _vm.getSummaries,
                },
                on: { "selection-change": _vm.setSelectRows },
              },
              [
                _c(
                  "el-table-column",
                  { attrs: { align: "center", type: "index", width: "50" } },
                  [
                    _c(
                      "template",
                      { slot: "header" },
                      [
                        _c(
                          "el-popover",
                          {
                            attrs: {
                              "popper-class": "custom-table-checkbox",
                              trigger: "hover",
                            },
                          },
                          [
                            _c(
                              "el-checkbox-group",
                              {
                                model: {
                                  value: _vm.checkList,
                                  callback: function ($$v) {
                                    _vm.checkList = $$v
                                  },
                                  expression: "checkList",
                                },
                              },
                              _vm._l(_vm.columns, function (item, index) {
                                return _c("el-checkbox", {
                                  key: index,
                                  attrs: { label: item.label },
                                  on: { change: _vm.filterChcekboxChange },
                                })
                              }),
                              1
                            ),
                            _c(
                              "el-button",
                              {
                                attrs: { slot: "reference", type: "text" },
                                slot: "reference",
                              },
                              [
                                _c("vab-remix-icon", {
                                  attrs: { icon: "settings-line" },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  2
                ),
                _c("el-table-column", {
                  attrs: {
                    align: "center",
                    type: "selection",
                    "reserve-selection": true,
                    width: "50",
                  },
                }),
                _vm._l(_vm.finallyColumns, function (item, index) {
                  return _c(
                    "el-table-column",
                    {
                      key: index,
                      attrs: {
                        align: "center",
                        label: item.label,
                        sortable: item.sortable,
                        width: "",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (ref) {
                              var row = ref.row
                              return [
                                item.label == "单号"
                                  ? _c("div", [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "under-line",
                                          on: {
                                            click: function ($event) {
                                              return _vm.checkOrder(row)
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "p",
                                            {
                                              class: /[-]/.test(row[item.prop])
                                                ? "red"
                                                : "",
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(row[item.prop]) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                      row.bill_type !== 2
                                        ? _c("div", [
                                            _c(
                                              "div",
                                              { staticClass: "btnwrap" },
                                              [
                                                _vm._l(
                                                  row.is_abnormal_type_text,
                                                  function (tag) {
                                                    return _c(
                                                      "el-tag",
                                                      { key: tag },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(tag) +
                                                            " "
                                                        ),
                                                      ]
                                                    )
                                                  }
                                                ),
                                                _vm._l(
                                                  row.sale_type_txt,
                                                  function (tag) {
                                                    return _c(
                                                      "el-tag",
                                                      { key: tag },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(tag) +
                                                            " "
                                                        ),
                                                      ]
                                                    )
                                                  }
                                                ),
                                                Number(row.discount_amount) != 0
                                                  ? _c("el-tag", [
                                                      _vm._v(" 优惠 "),
                                                    ])
                                                  : _vm._e(),
                                              ],
                                              2
                                            ),
                                          ])
                                        : _vm._e(),
                                      row.bill_type == 2
                                        ? _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                color: "#f60",
                                                cursor: "pointer",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.checkOrder2(row)
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(row.order_bill_code) +
                                                  " "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ])
                                  : item.label == "优惠"
                                  ? _c("div", [
                                      row[item.prop] != 0
                                        ? _c(
                                            "span",
                                            { staticStyle: { color: "red" } },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(row[item.prop]) +
                                                  " "
                                              ),
                                            ]
                                          )
                                        : _c("span", [
                                            _vm._v(_vm._s(row[item.prop])),
                                          ]),
                                    ])
                                  : _c("div", [_vm._v(_vm._s(row[item.prop]))]),
                              ]
                            },
                          },
                        ],
                        null,
                        true
                      ),
                    },
                    [
                      item.label == "毛利率"
                        ? _c(
                            "template",
                            { slot: "header" },
                            [
                              _c("span", [_vm._v("毛利率")]),
                              _c(
                                "el-popover",
                                {
                                  attrs: {
                                    placement: "bottom",
                                    title: "",
                                    width: "200",
                                    trigger: "click",
                                    content:
                                      "毛利=（商品售价-成本价）X数量 毛利率=利润/销售金额X100%",
                                  },
                                },
                                [
                                  _c("i", {
                                    staticClass: "el-icon-question",
                                    attrs: { slot: "reference" },
                                    slot: "reference",
                                  }),
                                ]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    2
                  )
                }),
                _c("el-table-column", {
                  attrs: {
                    align: "center",
                    label: "操作",
                    fixed: "right",
                    "show-overflow-tooltip": "",
                    width: "190",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (ref) {
                        var $index = ref.$index
                        var row = ref.row
                        return [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "text" },
                              on: {
                                click: function ($event) {
                                  return _vm.checkOrder(row)
                                },
                              },
                            },
                            [_vm._v("查看")]
                          ),
                          row.bill_status_text == "待对账"
                            ? [
                                row.bill_type_text == "车销" ||
                                row.bill_type_text == "仓库直销"
                                  ? _c(
                                      "el-button",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: row.order_bill_code == "",
                                            expression:
                                              "row.order_bill_code == ''",
                                          },
                                        ],
                                        attrs: { type: "text" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.editOrder(row)
                                          },
                                        },
                                      },
                                      [_vm._v(" 编辑 ")]
                                    )
                                  : _c(
                                      "el-button",
                                      {
                                        attrs: { type: "text" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.cancelArrival(row)
                                          },
                                        },
                                      },
                                      [_vm._v(" 取消到货 ")]
                                    ),
                              ]
                            : _vm._e(),
                          _c(
                            "el-button",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    row.bill_status == 1 &&
                                    row.bill_type_text !== "访销" &&
                                    _vm.authbutton[1].is_check == 1,
                                  expression:
                                    "\n                row.bill_status == 1 &&\n                row.bill_type_text !== '访销' &&\n                authbutton[1].is_check == 1\n              ",
                                },
                              ],
                              attrs: { type: "text" },
                              on: {
                                click: function ($event) {
                                  return _vm.handleDelete($index, row)
                                },
                              },
                            },
                            [_vm._v(" 作废 ")]
                          ),
                          row.bill_type == 4 || row.bill_type == 1
                            ? _c(
                                "el-button",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: 0,
                                      expression: "0",
                                    },
                                  ],
                                  attrs: { type: "text" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleCopy(row)
                                    },
                                  },
                                },
                                [_vm._v(" 复制 ")]
                              )
                            : _vm._e(),
                          _c(
                            "el-button",
                            {
                              attrs: { type: "text" },
                              on: {
                                click: function ($event) {
                                  return _vm.handlePrint($index, row)
                                },
                              },
                            },
                            [_vm._v(" 打印 ")]
                          ),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              2
            ),
            _c("el-pagination", {
              attrs: {
                "current-page": _vm.queryForm.pageNo,
                layout: _vm.layout,
                "page-size": _vm.queryForm.pageSize,
                total: _vm.total,
                background: "",
              },
              on: {
                "current-change": _vm.handleCurrentChange,
                "size-change": _vm.handleSizeChange,
              },
            }),
            _c("el-button", { on: { click: _vm.btnPrint } }, [
              _vm._v("批量打印"),
            ]),
            _c("check-order", { ref: "checkOrder" }),
            _c("edit", { ref: "edit", on: { refresh: _vm.fetchData } }),
          ],
          1
        ),
      ]),
      _c("ruilang-display-dialog", {
        ref: "RuilangDisplayDialog",
        attrs: { bill_type: "XS", data_id: _vm.data_id, type: 1 },
      }),
      _c("advance-order", { ref: "advanceOrder" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }